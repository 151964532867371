.overlay {
  position: no;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #efefef;
  border: none;
  z-index: 10;
  box-shadow: none!important;
  display: none;
}

iframe {
  width: 100%;
  height: 100vh;
  border: none;
  overflow: hidden;
}